import config from 'appConfig';
import {call, put, all, select, fork} from 'redux-saga/effects';

import {
  SET_CHECKOUT_LOADING,
  SET_DELIVERY_SLOTS,
  SET_PAYMENT_DETAILS,
  SET_PG_CUSTOMER_ID,
  addNewDeliveryAddress,
  SET_ORDER_CONFIRMATION,
  SET_PARTIAL_PAYMENT,
} from './checkout.action';
import {
  SET_CONFIRMATION_PAGE_MODAL,
  SET_NON_SERVICEABLE_MODAL,
  SET_SCRATCH_CARD_MODAL,
} from '../modal/modal.action';

import {
  getPaymentSummaryApi,
  getPaymentMethodsApi,
  getPGCustomerIdApi,
  postDeliveryAddressApi,
  getDeliverySlotsApi,
  postPlaceOrderApi,
  postSaveBnplPaymentApi,
  postSaveUserPaymentApi,
  postVerifyPaymentApi,
  postVerifyPaymentApiPayU,
  postDeliverySlotsApi,
  addNewDeliveryAddressApi,
  validateUserLocationApi,
  updateDeliveryAddressApi,
  getOrderConfirmationApi,
  validateSelectedLocationForNudgeApi,
  removeShoppingCartApi,
  subscribeNewUserApi,
  verifyPayUVpaApi,
} from './checkout.api';
import history from '../../history';
import {
  RESET_CART_SKUS,
  SET_CART_QTY_COUNT,
} from '../../feature/cart/cart.action';
import {
  launchInfoSelector,
  SET_NOTIFICATION_MSG,
  SET_LAUNCH_INFO,
} from '../../initial.action';
import {
  CHECKOUT_FAILURE_REDIRECT,
  CHECKOUT_STEPS,
  PAYMENT_GATEWAYS,
} from '../../util/constantServices';
import RazorpayService from '../../util/RazorpayService';
import PayuService from '../../util/PayuService';
import {getOrderDetailsApi} from '../accounts/accounts.api';
import {sendAnalytics} from 'hb-redux-store/analytics';
import {selectInitialData} from 'hb-redux-store/initial.selectors';
import {getMembershipBannerDetailSaga} from 'hb-redux-store/feature/halalboxPrime/hbPrime.saga';

const {trackGtmEvent = () => {}} = config;

export function* getPaymentSummarySaga({data = {}}) {
  try {
    yield put({type: SET_CHECKOUT_LOADING, data: true});
    const {data: summary} = yield call(getPaymentSummaryApi, data);
    data.cb && data.cb();
    yield put({type: SET_PAYMENT_DETAILS, data: {summary}});
  } catch (e) {
    console.log('Err@ getPaymentSummarySaga: ', e);
    yield put({type: SET_CHECKOUT_LOADING, data: false});
  }
}

export function* getPaymentDetailsSaga({data = {}}) {
  try {
    yield put({type: SET_CHECKOUT_LOADING, data: true});
    if (config.isApp) {
      yield put({type: SET_PARTIAL_PAYMENT, data: true});
    }
    const promises = [
      call(getPaymentSummaryApi, data),
      call(getPaymentMethodsApi, data),
    ];
    const [summaryResp, paymentMethodsResp] = yield all(promises);
    const summary = summaryResp.data;
    console.log('Details Api', summary);
    const paymentMethods = paymentMethodsResp.data;
    yield put({
      type: SET_PAYMENT_DETAILS,
      data: {summary, paymentMethods: paymentMethods.data},
    });
    const pgCid = paymentMethods.pgCid || null;
    const {pgId} = paymentMethods;
    let { savedUPIs = [] } = paymentMethods;
    const recommendedUpis = [], upis = [];
    savedUPIs.map(upi => {
      const vpa = { ...upi, tokenId: upi.tokenId || Math.random() };
      if (upi.isNumberVpa) recommendedUpis.push(vpa);
      else upis.push(vpa);
    });
    paymentMethods.savedUPIs = [...recommendedUpis, ...upis];
    // if (pgId === PAYMENT_GATEWAYS.PAYU.id) {
    //   savedUPIs = savedUPIs.map(upi => ({...upi, tokenId: Math.random()}));
    //   paymentMethods.savedUPIs = savedUPIs;
    // }
    // console.warn('getPaymentDetailsSaga', paymentMethods);

    // temp: ignore hdfc bank pg as its breaking:
    // paymentMethods.netBankings = paymentMethods.netBankings?.filter(({name}) => !(name?.toLowerCase()?.indexOf('hdfc') > -1))

    yield put({
      type: SET_PAYMENT_DETAILS,
      data: {summary, paymentMethods, pgCid},
    });
    if (!pgCid && pgId === PAYMENT_GATEWAYS.RAZORPAY.id) {
      const pgCidResp = yield call(getPGCustomerIdSaga);
      console.log('GoT PGCID:', pgCidResp);
    }
    yield put({type: SET_CHECKOUT_LOADING, data: false});
  } catch (e) {
    console.log('Err@ getPaymentDetailsSaga: ', e);
    yield put({type: SET_CHECKOUT_LOADING, data: false});
  }
}

export function* getPGCustomerIdSaga() {
  try {
    const {data: resp} = yield call(getPGCustomerIdApi);
    yield put({type: SET_PG_CUSTOMER_ID, data: resp});
    return resp;
  } catch (e) {
    console.log('Err@ getPGCustomerIdSaga: ', e);
  }
}

export function* postDeliveryAddressSaga({data, redirectURL}) {
  try {
    const resp = yield call(postDeliveryAddressApi, data);
    if (!config.isApp) {
      history.push(redirectURL);
    } else {
      redirectURL(resp);
    }
  } catch (e) {
    console.log('Err @postDeliveryAddressSaga: ', e, e.message);
  }
}

export function* addNewDeliveryAddressSaga({data, redirectURL}) {
  try {
    const {data: resp} = yield call(addNewDeliveryAddressApi, data);
    const launchData = yield select(launchInfoSelector);
    const launchInfo = {...launchData, ...resp};
    yield put({type: SET_LAUNCH_INFO, data: launchInfo});
    if (!config.isApp) {
      history.replace(redirectURL);
    } else {
      redirectURL();
    }
  } catch (e) {
    console.log('Err @addNewDeliveryAddressSaga: ', e, e.message);
  }
}

export function* updateDeliveryAddressSaga({data, cb}) {
  try {
    const {data: resp} = yield call(updateDeliveryAddressApi, data);
    const launchData = yield select(launchInfoSelector);
    const launchInfo = {...launchData, ...resp};
    yield put({type: SET_LAUNCH_INFO, data: launchInfo});
    if (cb) {
      cb();
    }
  } catch (e) {
    console.log('Err @updateDeliveryAddressSaga: ', e, e.message);
  }
}

export function* subscribeNewUserSaga({data, cb}) {
  try {
    const {data: resp} = yield call(subscribeNewUserApi, data);
    if (cb) {
      cb();
    }
  } catch (e) {
    console.log('Err @updateDeliveryAddressSaga: ', e, e?.message);
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message:
          e?.message ||
          e?.data?.description ||
          'Unable to subscribe the given email',
      },
    });
  }
}

export function* validateUserLocationSaga({data, cb}) {
  const payload = {
    address_line: data?.addressLine2,
    area: data?.area,
    city: data?.city,
    country: data?.country,
    latitude: data?.latitude,
    longitude: data?.longitude,
    map_location: data?.mapLocation,
    pin_code: data?.pinCode,
    primary_text: data?.primaryText,
    secondary_text: data?.secondaryText,
    state: data?.state
  }
  try {
    console.warn('validateUserLocationSaga', data);

    const {data: resp} = yield call(validateUserLocationApi, data);
    const address = resp.customerAddress || resp.visitorAddress;
    if (resp.resetCart) {
      yield put({type: SET_CART_QTY_COUNT});
      yield put({type: RESET_CART_SKUS});
    }
    const launchData = yield select(launchInfoSelector);
    const launchInfo = {...launchData, ...address};
    yield put({type: SET_LAUNCH_INFO, data: launchInfo});
    // const address = resp.customerAddress || resp.visitorAddress;
    if (!config.isApp) {
      if (cb) {
        cb();
      } else {
        history.push('/');
      }
    } else {
      cb(resp.resetCart);
    }
    // Analytics
    const key = 'Confirm Location';
    const payload = {
      ...data,
      customerType: launchInfo?.customerType,
    };
    sendAnalytics({
      key,
      payload,
      firebasePriority: 1,
      facebookPriority: 1
    });
  } catch (e) {
    const {launchInfo} = yield select(selectInitialData);
    // Analytics
    const key = 'Out Of Service Area';
    const payload = {
      ...data,
      customerType: launchInfo?.customerType,
    };
    sendAnalytics({
      key,
      payload,
      firebasePriority: 1,
      facebookPriority: 1
    });
    console.log('Err @validateUserLocationSaga in checkout: ', e);
    yield put({
      type: SET_NON_SERVICEABLE_MODAL,
      data: {visible: true, source: 'checkout'},
    });
  }
}

export function* getDeliverySlotsSaga() {
  try {
    // console.log('Getting delivery Slots data');
    yield put({type: SET_CHECKOUT_LOADING, data: true});
    const {data} = yield call(getDeliverySlotsApi);
    const showShipmentErrorMessage = !data?.shipments || !data?.shipments.length;
    yield put({type: SET_DELIVERY_SLOTS, data});
    if(showShipmentErrorMessage){
      yield put({
        type: SET_NOTIFICATION_MSG,
        data: {
          show: true,
          message: "Error while loading shipment set",
        },
      });
    }
    yield put({type: SET_CHECKOUT_LOADING, data: false});
  } catch (e) {
    console.log('Err @getDeliverySlotsSaga: ', e);
    let errorMsg = 'Error fetching shipments.';
    if (e.status === 401) {
      errorMsg = 'Unauthorised Access! Please login to access this service';
    }
    yield put({type: SET_CHECKOUT_LOADING, data: false});
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMsg,
      },
    });
  }
}

export function* postDeliverySlotsSaga({data, redirect = null, failCb = null}) {
  try {
    yield put({type: SET_CHECKOUT_LOADING, data: true});
    const deliverResp = yield call(postDeliverySlotsApi, data);
    yield put({type: SET_CHECKOUT_LOADING, data: false});
    if (!config.isApp) {
      let value = [],
        label = [];
      data.availableDeliverySlots.map(slot => {
        value.push(slot.deliverySlotId);
        label.push(
          `${slot.now ? 'NOW: ' : ''}${slot?.deliverySlotStartTime}: ${
            slot.slot
          }`,
        );
      });
      const gtmData = {
        eventName: 'addShippingInfo',
        eventData: {
          category: 'checkout > shipment',
          value: value.join(', '),
          label: label.join(', '),
        },
      };
      trackGtmEvent(gtmData);
      history.push(redirect);
    } else {
      redirect(deliverResp);
    }
  } catch (e) {
    console.log('Err @postDeliverySlotsSaga: ', e);
    if(e.data?.redirect){
      if(!config.isApp){
        if (data.failCb) data.failCb({step: CHECKOUT_STEPS.PLACE_ORDER, ...e.data});
        return
      }else{
        failCb({step: CHECKOUT_STEPS.PLACE_ORDER, ...e.data})
      }
    }
    let errorMsg = 'Error saving shipments.';
    if (e.status === 401) {
      errorMsg = 'Unauthorized Access! Please login to access this service';
    }
    if (e.data?.description || e.data?.messageResponseItem?.description) {
      errorMsg = e.data?.description || e.data?.messageResponseItem?.description;
    }
    yield put({type: SET_CHECKOUT_LOADING, data: false});
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMsg,
      },
    });
  }
}

export function* placeOrderSaga() {
  try {
    // const quantityFailure = {"action":"failure","data":{"status":"error","redirect":"cart","description":"Session item Quantity mismatch for cart item : EGG-jwlw"}};
    const data = yield call(postPlaceOrderApi);
    return data;
  } catch (e) {
    console.log('Err @placeOrderSage: ', e);
    return {data: {step: CHECKOUT_STEPS.PLACE_ORDER, error: e}};
  }
}

function* processBnplPayment({paymentResp, cardData}) {
  if (paymentResp?.status === 'success') {
    yield put({type: RESET_CART_SKUS});
    cardData.successCb && cardData.successCb({orderNo: paymentResp?.orderNo});
  } else if (paymentResp?.status === 'failure') {
    const failureUrl = `/co/payment?result=failed&reason=Fail%20to%20Pay%20Via%20BNPL.%20Please%20try%20another%20mode.`;
    history.replace(failureUrl);
    history.go();
  } else if (paymentResp?.status === 'pending') {
    PayuService.makeBnplPayment(paymentResp);
  }
}

export function* postPlaceOrderSaga({
  data: cardData,
  razorPayInstance = null,
  payUInstance = null,
  cb,
  appFailedCb = null,
  payUSuccessCb = null,
  payUFailureCb = null,
}) {
  try {
    const nonPOLMode = ['pod', 'cod', 'hbw'];
    if (
      !config.isApp &&
      cardData.pgId === PAYMENT_GATEWAYS.RAZORPAY.id &&
      !RazorpayService.getRazorPayInstance()
    ) {
      RazorpayService.initRazorpay();
    }
    const postDetails = {
      amount: cardData.amount,
      paymentMode: cardData.payVia,
      pgId: cardData.pgId,
      paymentMethod: 'POL',
      isPartialPayment: cardData?.isPartialPayment,
    };
    let label = [
      `pay_mode: ${cardData.mode}`,
      `transaction_amount: ${cardData.amount}`,
      `pg_id: ${cardData.pgId}`,
      `pay_via: ${cardData?.payVia || 'NA'}`,
    ];
    if (cardData?.payVia === 'CC') {
      postDetails.cardType = cardData?.cardType; // VISA/MC/RUPAY
      postDetails.last4 = cardData?.cardType;
      postDetails.saveVpa = !!cardData?.save;
      label.push(`extra: cardType-${cardData?.cardType}`);
    }
    if (cardData?.payVia === 'upi') {
      postDetails.vpa = cardData?.vpa;
      postDetails.saveVpa = !!cardData?.save;
      label.push(`extra: vpa-${cardData?.vpa}`);
    }
    if (cardData?.payVia === 'netbanking') {
      postDetails.bankCode = cardData?.bank;
      label.push(`extra: bank-${cardData?.bank}`);
    }
    if (cardData?.payVia === 'BNPL') {
      postDetails.bankCode = cardData?.bankcode;
      label.push(`extra: bank-${cardData?.bankcode}`);
    }
    if (cardData?.wallet) {
      postDetails.paymentWallet = cardData?.wallet;
      label.push(`extra: wallet-${cardData?.wallet}`);
    }
    if (nonPOLMode.indexOf(cardData?.mode) > -1) {
      postDetails.paymentMode = cardData?.mode;
      postDetails.paymentMethod = cardData?.mode;
      if (cardData.mode === 'hbw') {
        label[1] = `transaction_amount: ${cardData?.differenceAmount}`;
        label.push(`extra: wallet_balance-${cardData?.hbWalletAmount}`);
      }
    }
    if (!config.isApp) {
      const gtmData = {
        eventName: 'addPaymentInfo',
        eventData: {
          category: 'checkout > placeorder',
          label: label.join(', '),
          value: cardData.amount,
        },
      };
      trackGtmEvent(gtmData);
    }
    const launchInfo = yield select(launchInfoSelector);
    const {data: resp} = yield call(placeOrderSaga);
    // console.log('place order saga: ', JSON.stringify(resp));
    if (resp.step) {
      if (cardData.failCb && !config.isApp) {
        return cardData.failCb(resp);
      } else if (appFailedCb) {
        return appFailedCb(resp);
      }
    }
    // debugger
    // console.warn('postPlaceOrderApi', resp, cardData);
    const apiPayload = {
      ...postDetails,
      orderNo: resp?.orderNo || '',
      membershipRefNo: resp?.membershipRefNo || '',
    };
    const paymentData = {
      paymentMode: cardData.mode,
      data: apiPayload,
    };
    // console.warn('paymentData', paymentData);
    let paymentResp
    if (cardData?.payVia?.toUpperCase() === 'BNPL') {
      const {data: payResp} = yield call(postSaveBnplPaymentApi, paymentData);
      paymentResp = payResp;
    } else {
      const {data: payResp} = yield call(postSaveUserPaymentApi, paymentData);  
      paymentResp = payResp;
    }
    console.log(
      'save user payment: ',
      paymentData,
      JSON.stringify(paymentResp),
    );
    if (nonPOLMode.indexOf(cardData?.mode) > -1) {
      yield put({type: RESET_CART_SKUS});
      if (!config.isApp) {
        cardData.successCb && cardData.successCb({orderNo: resp?.orderNo});
      } else {
        cb({
          action: resp.status,
          data: {
            orderNo: resp.orderNo,
          },
        });
      }
    } else {
      if (!config.isApp) {
        if (cardData.pgId === PAYMENT_GATEWAYS.PAYU.id) {
          if (cardData?.payVia === 'BNPL') {
            return yield processBnplPayment({paymentResp, cardData});
          }
          const transactionId = !!resp.orderNo
            ? resp.orderNo
            : resp.membershipRefNo;
          const payuData = {
            ...postDetails,
            ...cardData,
            firstname: paymentResp.payUHash.hashFirstName,
            email: paymentResp.payUHash.hashEmail,
            phone: launchInfo.mobile,
            txnid: transactionId,
            hash: paymentResp.payUHash.paymentHash,
            productinfo: paymentResp.payUHash.productInfo,
            surl: paymentResp.payUHash.successURL,
            curl: paymentResp.payUHash.cancelURL,
            furl: paymentResp.payUHash.failureURL,
            amount: paymentResp.payUHash.amount,
            udf1: paymentResp.payUHash.udf1,
            udf2: paymentResp.payUHash.udf2,
            udf3: paymentResp.payUHash.udf3,
            udf4: paymentResp.payUHash.udf4,
            udf5: paymentResp.payUHash.udf5,
            key: process.env.REACT_APP_PAYU_KEY || 'LlaSG8',
            payuUrl: process.env.REACT_APP_PAYU_POST_URL,
          };
          payuData.user_credentials = `${payuData.key}:${cardData.pgCid}`;
          PayuService.makePayment(payuData);
          return;
        }
        const rzrp = RazorpayService.getRazorPayInstance();
        if (!rzrp) {
          return cardData.failCb({
            step: CHECKOUT_STEPS.PG_NOT_LOADED,
            redirect: CHECKOUT_FAILURE_REDIRECT.PG_NOT_LOADED,
          });
        }
        const rzrData = {
          customer_id: cardData.pgCid,
          order_id: paymentResp.pgOrderId,
          ...cardData,
          contact: launchInfo.mobile,
        };
        rzrData.amount = postDetails.amount * 100;
        delete rzrData.mode;
        delete rzrData.payVia;
        delete rzrData.pgCid;
        delete rzrData.pgId;
        delete rzrData.surl;
        delete rzrData.last4;
        delete rzrData.bin;
        delete rzrData.cardType;
        delete rzrData.isRechargeForOrder;
        delete rzrData.orderNo;
        delete rzrData.source;
        const razorPaySuccessCb = resp => {
          console.log('PaymentSuccess: ', resp);
          const verifyData = {
            status: 'success',
            orderNo: paymentData.orderNo,
            membershipRefNo: paymentData.membershipRefNo || '',
            success: {
              pgOId: resp.razorpay_order_id,
              pgPId: resp.razorpay_payment_id,
              pgSId: resp.razorpay_signature,
            },
          };
          postVerifyPaymentApi({orderNo: paymentData.orderNo, data: verifyData})
            .then(verifiedResp => {
              console.log('Payment verified', verifiedResp);
              if (!config.isApp) {
                cardData.successCb &&
                  cardData.successCb({orderNo: paymentData.orderNo});
              }
            })
            .catch(e => {
              console.log('Verification error: ', e);
              cardData.failCb &&
                cardData.failCb({
                  step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
                  error: {redirect: CHECKOUT_FAILURE_REDIRECT.ORDER_FAILURE},
                });
            });
        };
        const razorPayErrorCb = err => {
          console.log('RazorPay error: ', err);
          postVerifyPaymentApi({
            orderNo: paymentData.orderNo,
            data: {
              status: 'failure',
              ...err,
            },
          })
            .then(failedResp => {
              console.log('Failed payment reported. ', failedResp);
              if (!config.isApp) {
                cardData.failCb({
                  errorMessage:
                    err?.error?.description || failedResp.data?.description,
                });
              }
            })
            .catch(e => {
              console.log('Failure reporting error: ', e);
            });
        };
        const {deviceDetector = null} = config;
        console.log('deviceDetector: ', deviceDetector?.deviceDetect());
        console.log(
          'isIos device: ',
          deviceDetector?.isMacOs,
          deviceDetector?.isMobileSafari,
          deviceDetector?.isSafari,
          deviceDetector?.isIOS,
        );
        if (
          deviceDetector?.isMacOs ||
          deviceDetector?.isMobileSafari ||
          deviceDetector?.isSafari ||
          deviceDetector?.isIOS
        ) {
          console.log('Qualifying as apple device.');
          cardData.failCb({
            step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
            redirect: CHECKOUT_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
            pgDetails: {
              razorPayData: rzrData,
              razorPaySuccessCb,
              razorPayErrorCb,
            },
          });
        } else {
          const payNow = document.createElement('BUTTON');
          payNow.onclick = function() {
            console.log('Qualifying as non-apple device.');
            const razorPayPopup = RazorpayService.makePayment({
              razorPayData: rzrData,
              razorPaySuccessCb,
              razorPayErrorCb,
            });
            setTimeout(() => {
              const hasPopup = !!razorPayPopup?._payment?.popup?.window
                ?.outerHeight;
              // debugger
              if (!hasPopup) {
                console.log('non-apple device popup failed');
                cardData.failCb({
                  step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
                  redirect: CHECKOUT_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
                  pgDetails: {
                    razorPayData: rzrData,
                    razorPaySuccessCb,
                    razorPayErrorCb,
                  },
                });
              }
            }, 250);
          };
          payNow.click();
        }
        // console.log('Razr data: ', rzrData);
        // rzrp.on('payment.success', razorpaySuccessCb);
        // rzrp.on('payment.error', razorpayErrorCb);
        // const razorPayPopup = rzrp.createPayment(rzrData);
        // setTimeout(() => {
        //   // TODO: Update logic to recognise if modal is opened or not.
        //   const hasPopup = !!razorPayPopup._payment?.popup?.window?.outerHeight;
        //   if (!hasPopup) {
        //     cardData.failCb({
        //       step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
        //       redirect: CHECKOUT_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
        //       pgDetails: {
        //         rzrData,
        //         razorpaySuccessCb,
        //         razorpayErrorCb,
        //       }
        //     });
        //   }
        // }, 200);
      } else {
        if (cardData?.paymentMethod === 'POL') {
          delete cardData?.paymentMethod;
        }
        console.warn(
          'isPayu',
          cardData.pgId === PAYMENT_GATEWAYS.PAYU.id,
          payUInstance,
        );
        if (cardData.pgId === PAYMENT_GATEWAYS.PAYU.id) {
          const transactionNo = !!resp.orderNo
            ? resp?.orderNo
            : resp?.membershipRefNo;
          const payuData = {
            ...postDetails,
            ...cardData,
            firstName: paymentResp.payUHash.hashFirstName,
            email: paymentResp.payUHash.hashEmail,
            phone: launchInfo.mobile,
            transactionId: transactionNo,
            hash: paymentResp.payUHash.paymentHash,
            productInfo: paymentResp.payUHash.productInfo,
            android_surl: paymentResp.payUHash.successURL,
            android_furl: paymentResp.payUHash.failureURL,
            ios_surl: paymentResp.payUHash.successURL,
            ios_furl: paymentResp.payUHash.failureURL,
            amount: paymentResp.payUHash.amount,
            udf1: paymentResp.payUHash.udf1,
            udf2: paymentResp.payUHash.udf2,
            udf3: paymentResp.payUHash.udf3,
            udf4: paymentResp.payUHash.udf4,
            udf5: paymentResp.payUHash.udf5,
            key: config.ENV.PG_PU_KEY,
            payment: paymentResp.payUHash.paymentHash,
            vas_for_mobile_sdk: paymentResp.payUHash.vasForMobileSDK,
            payment_related_details_for_mobile_sdk:
              paymentResp.payUHash.paymentRelatedDetailsForMobileSDK,
            payUEnvironment: parseInt(config.ENV.PG_PU_ENVIRONMENT, 10),
            payUIosEnvironment: config.ENV.PG_PU_IOS_ENVIRONMENT,
          };
          payuData.userCredentials = `${payuData.key}:${cardData.pgCid}`;
          console.warn('paymentResp', paymentResp);
          console.warn('paymentObject', payuData);
          var paymentObject = {
            payUPaymentParams: payuData,
            payUCheckoutProConfig: {},
          };

          let finalPayUPayload = paymentObject;
          if (config?.getDeviceType() === 'iphone') {
            finalPayUPayload = payuData;
          }

          // Analytics
          const key = 'pg_payu_initialize';
          const payload = {
            ...postDetails,
            firstName: paymentResp.payUHash.hashFirstName,
            email: paymentResp.payUHash.hashEmail,
            phone: launchInfo.mobile,
            transactionId: transactionNo,
            productInfo: paymentResp.payUHash.productInfo,
            android_surl: paymentResp.payUHash.successURL,
            android_furl: paymentResp.payUHash.failureURL,
            ios_surl: paymentResp.payUHash.successURL,
            ios_furl: paymentResp.payUHash.failureURL,
            amount: paymentResp.payUHash.amount,
            udf1: paymentResp.payUHash.udf1,
            udf2: paymentResp.payUHash.udf2,
            udf3: paymentResp.payUHash.udf3,
            udf4: paymentResp.payUHash.udf4,
            udf5: paymentResp.payUHash.udf5,
            payUEnvironment: parseInt(config.ENV.PG_PU_ENVIRONMENT, 10),
            payUIosEnvironment: config.ENV.PG_PU_IOS_ENVIRONMENT,
            deviceId: config.getDeviceId(),
          };
          sendAnalytics({
            key,
            payload,
            firebasePriority: 1,
          });

          payUInstance.openCheckoutScreen(
            finalPayUPayload,
            (payUresponse, merchantResponse = {}) => {
              // Analytics
              const key = 'pg_payu_error';
              const payload = {
                payUResponse: payUresponse,
                merchantResponse: merchantResponse || '',
                deviceId: config.getDeviceId(),
              };
              sendAnalytics({
                key,
                payload,
                firebasePriority: 1,
              });

              // error callback
              console.warn('PayU Error', payUresponse, '+', merchantResponse);
              let responseData = {},
                verifyData = {};
              try {
                responseData = JSON.parse(payUresponse);
              } catch (error) {
                responseData = payUresponse;
              }

              try {
                verifyData = {
                  status: 'failure',
                  orderNo: resp.orderNo || '',
                  membershipRefNo: resp.membershipRefNo || '',
                  sdkCallBackStatus: 'failure',
                  payUResponse: responseData,
                  ...merchantResponse,
                };
              } catch (error) {
                verifyData = {
                  status: 'failure',
                  orderNo: resp.orderNo || '',
                  membershipRefNo: resp.membershipRefNo || '',
                  sdkCallBackStatus: 'failure',
                  payUResponse: responseData,
                  merchantResponse,
                };
              }
              postVerifyPaymentApiPayU({
                data: verifyData,
              })
                .then(validateResponse => {
                  // payU error with validation error success
                  console.warn('payU error with validation API success');
                  // payUFailureCb(payUresponse, merchantResponse);
                  // Analytics
                  const key = 'pg_payu_error_validate_success';
                  const payload = {
                    response: JSON.stringify(validateResponse),
                    orderNo: resp.orderNo,
                    status: 'failure',
                    payUresponse: payUresponse || '',
                    merchantResponse: merchantResponse || '',
                    sdkCallBackStatus: 'failure',
                    deviceId: config.getDeviceId(),
                  };
                  sendAnalytics({
                    key,
                    payload,
                    firebasePriority: 1,
                  });

                  payUSuccessCb(
                    payUresponse,
                    merchantResponse,
                    validateResponse,
                  );
                })
                .catch(err => {
                  // payU error with validation failed
                  console.warn('payU error with validation failed', err);

                  // Analytics
                  const key = 'pg_payu_error_validate_error';
                  const payload = {
                    orderNo: resp.orderNo,
                    status: 'failure',
                    payUresponse: payUresponse || '',
                    merchantResponse: merchantResponse || '',
                    sdkCallBackStatus: 'failure',
                    description: err?.data?.description,
                    orderStatus: err?.data?.orderStatus,
                    deviceId: config.getDeviceId(),
                  };
                  sendAnalytics({
                    key,
                    payload,
                    firebasePriority: 1,
                  });

                  payUFailureCb(payUresponse, merchantResponse);
                });
            },
            (payUresponse, merchantResponse) => {
              // Analytics
              const key = 'pg_payu_success';
              const payload = {
                req: finalPayUPayload,
                res: {
                  ...payUresponse,
                  ...merchantResponse,
                },
                deviceId: config.getDeviceId(),
              };
              sendAnalytics({
                key,
                payload,
                firebasePriority: 1,
              });

              //success callback
              const verifyData = {
                orderNo: resp.orderNo || '',
                membershipRefNo: resp.membershipRefNo || '',
                sdkCallBackStatus: 'success',
                status: 'success',
                payUResponse: JSON.parse(payUresponse),
                ...merchantResponse,
              };
              console.warn('PayU success', payUresponse, merchantResponse);
              postVerifyPaymentApiPayU({
                data: verifyData,
              })
                .then(validateResponse => {
                  // payU success with validation success
                  console.warn('payU success with validation success');
                  // Analytics
                  const key = 'pg_payu_success_validate_success';
                  const payload = {
                    response: JSON.stringify(validateResponse),
                    orderNo: resp?.orderNo,
                    status: 'success',
                    payUresponse: payUresponse || '',
                    merchantResponse: merchantResponse || '',
                    sdkCallBackStatus: 'success',
                    deviceId: config.getDeviceId(),
                  };
                  sendAnalytics({
                    key,
                    payload,
                    firebasePriority: 1,
                  });

                  payUSuccessCb(
                    payUresponse,
                    merchantResponse,
                    validateResponse,
                  );
                })
                .catch(err => {
                  // payU success with validation failed
                  console.warn('payU success with validation failed', err);
                  // Analytics
                  const key = 'pg_payu_success_validate_error';
                  const payload = {
                    orderNo: resp?.orderNo,
                    status: 'success',
                    payUresponse: payUresponse || '',
                    merchantResponse: merchantResponse || '',
                    sdkCallBackStatus: 'success',
                    description: err?.data?.description,
                    orderStatus: err?.data?.orderStatus,
                    deviceId: config.getDeviceId(),
                  };
                  sendAnalytics({
                    key,
                    payload,
                    firebasePriority: 1,
                  });

                  payUFailureCb(payUresponse, merchantResponse);
                });
            },
          );
          return;
        }

        // APP RAZORPAY

        const rzrData = {
          customer_id: cardData.pgCid,
          order_id: paymentResp.pgOrderId,
          ...cardData,
          contact: launchInfo.mobile,
          currency: 'INR',
        };
        rzrData.amount = postDetails.amount * 100;
        delete rzrData.mode;
        delete rzrData.payVia;
        delete rzrData.pgCid;
        delete rzrData.pgId;
        delete rzrData.surl;

        console.warn('Payment by App', rzrData, config.ENV.PG_KEY);
        razorPayInstance
          .open(rzrData)
          .then(resp => {
            // handle success
            const verifyData = {
              status: 'success',
              orderNo: paymentData.orderNo,
              membershipRefNo: paymentData.membershipRefNo || '',
              success: {
                pgOId: resp?.razorpay_order_id,
                pgPId: resp?.razorpay_payment_id,
                pgSId: resp?.razorpay_signature,
              },
            };
            console.warn('Razorpay verifyData', verifyData);

            postVerifyPaymentApi({
              data: verifyData,
            })
              // Verify Payment Transaction
              .then(verifiedResp => {
                console.log('Payment verified', verifiedResp);
                if (config.isApp) {
                  // here fail
                  cb(verifiedResp);
                }
              })
              .catch(e => {
                console.log('Verification error: ', e);
                appFailedCb({
                  step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
                  redirect: CHECKOUT_FAILURE_REDIRECT.ORDER_FAILURE,
                });
              });
          })
          .catch(err => {
            // handle failure
            console.warn('RazorPay error: ', err);
            let errorMsg = '';
            let statusCode = '';
            try {
              errorMsg = JSON.parse(err?.description || {})?.error;
              statusCode = JSON.parse(err?.description || {})?.http_status_code;
            } catch (e) {
              console.warn('Error Parsing Razorpay error', e);
              errorMsg = {
                error: err?.description,
              };
              statusCode = '';
            }
            postVerifyPaymentApi({
              orderNo: paymentData.orderNo,
              data: {
                status: 'failure',
                error: errorMsg,
                http_status_code: statusCode,
              },
            })
              .then(failedResp => {
                // const formattedError = (failedResp?.data?.description ) || (err?.description && JSON.parse(err?.description) && JSON.parse(err?.description)?.error?.description);
                console.warn(
                  'Failed payment reported. ',
                  failedResp,
                  JSON.parse(err?.description).description,
                );
                // appFailedCb(formattedError);

                appFailedCb(failedResp);
              })
              .catch(e => {
                console.warn('Failure reporting error: ', e);
                cb(err?.description);
              });
          });
      }
    }
  } catch (e) {
    console.log('Err @postPlaceOrderSaga: ', e);
    cb && cb(e);
    cardData.failCb && cardData.failCb(e);
    let errorMsg = 'Error while placing order. Please try again.';
    if (e.data?.description) {
      errorMsg = e.data?.description;
    }
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMsg,
      },
    });
  }
}

export function* verifyVPASaga({data}) {
  try {
    const {pgId, vpa} = data;
    if (pgId === PAYMENT_GATEWAYS.PAYU.id) {
      const [username, handle] = vpa.split('@');
      const {data: vpaResp} = yield call(verifyPayUVpaApi, {username, handle});
      data.successCb();
    } else {
      if (!config.isApp) {
        const rzrp = RazorpayService.getRazorPayInstance();
        rzrp
          .verifyVpa(data.vpa)
          .then(() => {
            data.successCb();
          })
          .catch(() => {
            data.failureCb();
          });
      } else {
        data.successCb();
      }
    }
  } catch (e) {
    console.log('Err @verifyVPA: ', e);
    data.failureCb();
  }
}

// function _getRazorPayInstance() {
//   const razorpay = new window.Razorpay({
//     key: process.env.REACT_APP_RAZORPAY_KEY || 'rzp_test_HGYJ5cxxUlMj95',
//     image:
//       'https://ik.imagekit.io/iwcam3r8ka/random/202005/b440faf6-3cd7-4859-a30e-a8b7a1a554fd.png',
//   });
//   console.log('Got RazorPayInstance: ', !!razorpay);
//   return razorpay;
// }

export function* getOrderConfirmationSaga({data}) {
  try {
    const {launchInfo} = yield select(selectInitialData);
    yield put({type: SET_CHECKOUT_LOADING, data: true});
    const {data: orderResp} = yield call(getOrderConfirmationApi, data);
    const {orderDetails} = orderResp;
    const {cartItems = []} = orderDetails || {};
    if (orderResp?.scratchCard) {
      const payload = {
        visible: true,
        source: 'confirmation',
        data: orderResp?.scratchCard,
      };
      yield put({type: SET_SCRATCH_CARD_MODAL, data: payload});
    }
    if (orderResp?.isMissionOxygenActive) {
      yield put({type: SET_CONFIRMATION_PAGE_MODAL, data: true});
    }
    if (!config.isApp) {
      // ==== Sending FB purchase event
      // ==== https://www.facebook.com/business/help/402791146561655?id=1205376682832142
      const trackedOrder = config.LSGetItem('trackedOrder') || {};
      if (!trackedOrder[data.orderNo]) {
        // debugger
        window?.fbq &&
          window.fbq('track', 'Purchase', {
            value: orderResp?.orderPayableAmount,
            currency: 'INR',
            orderNo: data.orderNo,
            purchaseValue: orderResp?.orderPayableAmount,
            customerId: config.getDeviceId(),
          });
        console.log('tracked using fb:');
        const gtmData = {
          eventName: 'checkoutSuccess',
          eventData: {
            category: 'checkout > successful_order',
            value: orderResp?.orderPayableAmount,
            label: data.orderNo,
          },
        };
        trackGtmEvent(gtmData);
        console.log('tracked using GTM:');
        const {ga} = config.ReactGA;
        // Sending ecommerce Analytics.js
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce
        ga('require', 'ecommerce');
        const transactionProducts = [];
        cartItems.map(oi => {
          const item = {
            id: oi.sku,
            sku: oi.sku,
            name: oi.productName,
            price: oi.onSale ? oi.salePrice : oi.retailPrice,
            quantity: oi.inCartQty || oi.quantity,
            currency: 'INR',
          };
          transactionProducts.push(item);
          ga('ecommerce:addItem', item);
        });
        // debugger
        ga('ecommerce:addTransaction', {
          id: data.orderNo,
          revenue: orderResp?.orderPayableAmount,
          shipping:
            orderResp.orderDetails?.orderDetails?.priceDetails?.deliveryCharges,
          currency: 'INR',
        });
        ga('ecommerce:send');
        ga('ecommerce:clear');
        console.log('tracked using GA:');
        //==============   Standard Ecomm using universal analytics
        //==============   https://support.google.com/tagmanager/answer/6107169?authuser=5#standard-ecommerce
        // const ecomData = {
        //   transactionId: odResp.orderNo,
        //   transactionTotal: odResp.priceDetails?.orderPayableAmount,
        //   transactionShipping: odResp.priceDetails?.deliveryCharges,
        //   transactionProducts,
        //   event: 'Purchase',
        // };
        // // console.log('GTM_dataLayer: ', !!window?.dataLayer);
        // const dataLayer = window.dataLayer = window.dataLayer || [];
        // // console.log('EComm Data: ', ecommData);
        // dataLayer.push(ecomData);

        // ==============    Sending ecommerce with gtag.js
        // ==============    https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce
        const gtagData = {
          transaction_id: data.orderNo,
          value: orderResp?.orderPayableAmount,
          currency: 'INR',
          tax: 0,
          shipping: orderResp.orderDetails?.priceDetails?.deliveryCharges,
          items: transactionProducts,
          event: 'purchase',
        };
        // debugger
        const noOp = () => {};
        const gtag = window.gtag || noOp;
        gtag('event', 'purchase', gtagData);
        const gtmDataLayer = window?.dataLayer || [];
        gtmDataLayer.push(gtagData);
        console.log('tracked using gtag.js:');

        // <!-- AdWords Event snippet for Purchase Website conversion page -->
        gtag('event', 'conversion', {
          send_to: '%REACT_APP_AD_WORDS_ID%/oYruCKDH7YwDENzH5KEC',
          value: orderResp?.orderPayableAmount,
          currency: 'INR',
          transaction_id: data.orderNo,
        });
        console.log('tracked purchase for adwords:');

        trackedOrder[data.orderNo] = new Date();
        config.LSSetItem('trackedOrder', trackedOrder);
      }
    }
    yield put({type: SET_ORDER_CONFIRMATION, data: orderResp});
    yield put({type: RESET_CART_SKUS});
    // if(launchInfo?.displayPrime && orderResp?.orderType !== 'POL') yield fork(getMembershipBannerDetailSaga);
  } catch (e) {
    console.log('Err @getOrderConfirmationSaga: ', e);
  }
}

export function* validateSelectedLocationForNudgeSaga({data, cb = null}) {
  console.warn('validateSelectedLocationForNudgeSaga', data);

  try {
    const {data: resp} = yield call(validateSelectedLocationForNudgeApi, data);
    console.warn('validateSelectedLocationForNudgeSaga', resp, data);

    if (resp?.resetCart) {
      yield put({type: SET_CART_QTY_COUNT});
      yield put({type: RESET_CART_SKUS});
    }
    if (cb) {
      cb(resp);
    }
  } catch (e) {
    console.log('Err @validateSelectedLocationForNudgeSaga in checkout: ', e);
    if (cb && !config.isApp) {
      cb({...e, error: true});
    }
    yield put({
      type: SET_NON_SERVICEABLE_MODAL,
      data: {visible: true, source: 'checkout'},
    });
  }
}

export function* removeShoppingCartSaga({data, cb = null}) {
  try {
    const {data: resp} = yield call(removeShoppingCartApi, data);
    const address = resp.customerAddress || resp.visitorAddress;
    const launchData = yield select(launchInfoSelector);
    const launchInfo = {...launchData, ...address};
    yield put({type: SET_LAUNCH_INFO, data: launchInfo});
    if (resp?.resetCart) {
      yield put({type: SET_CART_QTY_COUNT});
      yield put({type: RESET_CART_SKUS});
    }
    if (cb) {
      cb(resp);
    }
  } catch (e) {
    console.log('Err @removeShoppingCartSaga in checkout: ', e);
    yield put({
      type: SET_NON_SERVICEABLE_MODAL,
      data: {visible: true, source: 'checkout'},
    });
  }
}

import {
  SET_BLOGS_WITH_CATEGORY,
  SET_LOADING,
  SET_BLOG_BY_SLUG,
  SET_BLOGS_BY_SEGMENT,
  RESET_BLOG_DETAILS,
  SET_RECENT_BLOG,
  SET_RECENT_BLOG_LOADING,
} from './blog.action';

const initialState = {
  allBlogs: null,
  loading: false,
  loadingRecentBlog: false,
  blog: null,
  segment: null,
  blogCategories: null,
  recentBlogs: null,
  hasMoreBlogs: true,
};

const blogReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_BLOGS_WITH_CATEGORY:
      return {
        ...state,
        ...data,
        loading: false
      };
    case SET_BLOGS_BY_SEGMENT:
      return {
        ...state,
        segment: data,
        loading: false
      };
    case SET_BLOG_BY_SLUG:
      return {
        ...state,
        blog: data,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data
      };
    case SET_RECENT_BLOG_LOADING:
      return {
        ...state,
        loadingRecentBlog: data
      };
    case RESET_BLOG_DETAILS:
      return {
        ...state,
        blog: null,
      };
    case SET_RECENT_BLOG:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};

export default blogReducer;

import { takeEvery } from 'redux-saga/effects';
import {
  GET_BLOGS_BY_CATEGORY,
  GET_BLOG_BY_SLUG,
  GET_BLOGS_BY_SEGMENT,
  GET_RECENT_BLOG,
} from './blog.action';
import {
  getBlogBySlugSaga,
  getBlogsWithCategorySaga,
  getBlogsWithSegmentSaga,
  getRecentBlogsSaga,
} from './blog.saga';

export const BlogSaga = [
  takeEvery(GET_BLOG_BY_SLUG, getBlogBySlugSaga),
  takeEvery(GET_BLOGS_BY_CATEGORY, getBlogsWithCategorySaga),
  takeEvery(GET_BLOGS_BY_SEGMENT, getBlogsWithSegmentSaga),
  takeEvery(GET_RECENT_BLOG, getRecentBlogsSaga),
];

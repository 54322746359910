import { call, put, all, select } from 'redux-saga/effects';
import {
  SET_BLOGS_WITH_CATEGORY,
  SET_LOADING,
  SET_BLOG_BY_SLUG,
  SET_BLOGS_BY_SEGMENT,
  SET_RECENT_BLOG,
  SET_RECENT_BLOG_LOADING,
  recentBlogsSelector,
} from './blog.action';
import {
  getBlogBySlugApi,
  getBlogsWithCategoryApi,
  getBlogsWithSegmentApi,
  getRecentBlogsApi
} from './blog.api';
import history from '../../history';
import config from '../../../appConfig';
import { getEstimatedReadTime } from '../../util/miscellaneousServices';
import moment from 'moment/moment';
import { getDeviceType } from '../../util/DeviceDetectionService';
import { DEVICE_TYPE } from '../../util/constantServices';

export function* getBlogsWithCategorySaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const [categoryBlogs, recentPosts] = yield all([
      call(getBlogsWithCategoryApi),
      call(getRecentBlogsSaga, {}),
    ]);
    const blogCategories = [{ blogCategoryId: 0, header: 'All' }];
    if (categoryBlogs?.data?.map) categoryBlogs.data.map((categoryDetails) => {
        let activeBlogCount = 0;
        if (categoryDetails?.blogsSubCategoryResponse.length)
          categoryDetails.blogsSubCategoryResponse.forEach(subCat => {
            if (subCat.sectionTypeBlogsResponse?.length)
              subCat.sectionTypeBlogsResponse.forEach(
                ({ status }) => status && activeBlogCount++
              );
          });
        const { blogCategoryId, header } = categoryDetails;
        if (activeBlogCount > 0)
          blogCategories.push({ blogCategoryId, header });
      });
    yield put({
      type: SET_BLOGS_WITH_CATEGORY,
      data: {
        blogCategories,
        allBlogs: categoryBlogs.data,
        recentBlogs: recentPosts.data,
      },
    });
  } catch (e) {
    console.log('Err@ getBlogsWithCategorySaga: ', e);
    yield put({ type: SET_LOADING, data: false });
  }
}

export function* getBlogBySlugSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: resp } = yield call(getBlogBySlugApi, data);
    yield put({
      type: SET_BLOG_BY_SLUG,
      data: {
        ...resp,
        readTime: resp.readTime || getEstimatedReadTime(resp.htmlContent),
        updatedAt: moment(resp.updatedAt).format('MMM DD, YYYY'),
      }
    });
    data.successOpCb && data.successOpCb(resp);
  } catch (e) {
    console.log('Err@ getBlogBySlugSaga: ', e);
    yield put({ type: SET_BLOG_BY_SLUG, data: 'invalid url' });
  }
}

export function* getRecentBlogsSaga({ data = {} }) {
  try {
    const isMobileDevice = getDeviceType() === DEVICE_TYPE.MWEB;
    const { page = 0, count = isMobileDevice ? 6 : 12 } = data;
    if (!page) {
      yield put({ type: SET_LOADING, data: true });
    } else {
      yield put({ type: SET_RECENT_BLOG_LOADING, data: true });
    }
    const { data: resp } = yield call(getRecentBlogsApi, { page, count });
    let apiBlogs = resp?.map(b => ({
      ...b,
      readTime: b.readTime || getEstimatedReadTime(b.htmlContent)
    }));
    const hasMoreBlogs = apiBlogs?.length === count;
    if (!!data.page) {
      const blogs = (yield select(recentBlogsSelector)) || [];
      if (apiBlogs?.length) apiBlogs = [...blogs, ...apiBlogs];
    }
    yield put({ type: SET_RECENT_BLOG, data: { recentBlogs: apiBlogs, hasMoreBlogs } });
    if (!page) {
      yield put({ type: SET_LOADING, data: false });
    } else {
      yield put({ type: SET_RECENT_BLOG_LOADING, data: false });
    }
    return { data: apiBlogs };
  } catch (e) {
    console.log('Err@ getRecentBlogsSaga: ', e);
    // yield put({ type: SET_RECENT_BLOG, data: 'invalid url' });
    yield put({ type: SET_LOADING, data: false });
  }
}

export function* getBlogsWithSegmentSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { subcategoryId } = data;
    const { data: resp } = yield call(getBlogsWithSegmentApi);
    let segment = null;
    resp.map(cat => {
      if (cat?.blogsSubCategoryResponse?.map) {
        cat.blogsSubCategoryResponse.map(subCat => {
          if (subCat.blogSubcategoryId == subcategoryId) {
            segment = subCat;
          }
        });
      }
    });
    yield put({
      type: SET_BLOGS_BY_SEGMENT,
      data: segment,
    });
  } catch (e) {
    console.log('Err@ getBlogsWithSegmentSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
  }
}

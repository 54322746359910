import {
  SET_MERGE_CART_ON_LOGIN_MODAL,
  SET_NON_SERVICEABLE_MODAL,
  SET_ORDER_FAILED_MODAL,
  SET_PREORDER_MODAL,
  SET_LOGIN_MODAL,
  SET_ADDRESS_MODAL,
  SET_LOCATION_SEARCH_MODAL,
  SET_LOCATION_MAP,
  SET_ADDRESSLIST_MODAL,
  SET_SUBSCRIPTION_SUMMARY_MODAL,
  SET_PAYMENT_PENDING_MODAL,
  SET_RECHARGE_FOR_ORDER_MODAL,
  SET_CONFIRMATION_PAGE_MODAL,
  SET_PAYMENT_FAIL_MODAL,
  SET_PAYMENT_SUMMARY_MODAL,
  SET_EARNING_HISTORY_MODAL,
  SET_SCRATCH_CARD_MODAL,
  SET_MULTIPLE_SKU_MODAL
} from './modal.action';
const initialState = {
  nonServiceableModal: false,
  nonServiceableSource: null, // location, cart, checkout
  mergeCartOnLoginModal: false,
  mergeCartSource: null,
  orderFailedModal: false,
  preorderModal: {visible: false},
  loginModal: false,
  addressModal: false,
  locationSearchModal: false,
  confirmLocationMap: false,
  addressListModal: false,
  typeOfCTA: 'location',
  showSubscriptionSummary: false,
  paymentPendingModal: false,
  rechargeForOrderModal: { visible: false, walletProps: null },
  confirmationModal: false,
  earningHistoryModal: false,
  multipleSkuModal: {
    visible: false,
    data: {},
  },
  paymentFailModal: {
    visible: false,
    reason: null,
  },
  paymentSummaryModal: { visible: false, summary: [] },
  scratchCardModal: {
    visible: false,
    source: null,
    data: {},
  },
};

const modalReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_NON_SERVICEABLE_MODAL:
      return {
        ...state,
        nonServiceableModal: data.visible,
        nonServiceableSource: data.source,
      };
    case SET_MERGE_CART_ON_LOGIN_MODAL:
      return {
        ...state,
        // mergeCartOnLoginModal: data,
        // mergeCartSource: 'CART',
        ...data,
      };
    case SET_ORDER_FAILED_MODAL:
      return {
        ...state,
        orderFailedModal: data,
      };
    case SET_PREORDER_MODAL:
      let preorderModal = {visible: false};
      if (data.visible) preorderModal = {...data};
      return {
        ...state,
        preorderModal,
      };
    case SET_LOGIN_MODAL:
      return {
        ...state,
        loginModal: data,
      };
    case SET_ADDRESS_MODAL:
      return {
        ...state,
        addressModal: data,
      };
    case SET_LOCATION_SEARCH_MODAL:
      return {
        ...state,
        locationSearchModal: data.locationSearchModal,
        typeOfCTA: data.typeOfCTA,
      };
    case SET_LOCATION_MAP:
      return {
        ...state,
        confirmLocationMap: data,
      };
    case SET_ADDRESSLIST_MODAL:
      return {
        ...state,
        addressListModal: data,
      };
    case SET_SUBSCRIPTION_SUMMARY_MODAL:
      return {
        ...state,
        showSubscriptionSummary: data,
      };
    case SET_PAYMENT_PENDING_MODAL:
      return {
        ...state,
        paymentPendingModal: data,
      };
    case SET_RECHARGE_FOR_ORDER_MODAL:
      let rechargeForOrderModal = {visible: false, walletProps: null};
      if (data.visible) rechargeForOrderModal = {...data};
      return {
        ...state,
        rechargeForOrderModal,
      };
    case SET_CONFIRMATION_PAGE_MODAL:
      return {
        ...state,
        confirmationModal:data
      };
    case SET_PAYMENT_FAIL_MODAL:
      return {
        ...state,
        paymentFailModal: { ...data },
      };
    case SET_PAYMENT_SUMMARY_MODAL:
      return {
        ...state,
        paymentSummaryModal: { ...data },
      };
    case SET_EARNING_HISTORY_MODAL:
      return {
        ...state,
        earningHistoryModal: data,
      };
    case SET_SCRATCH_CARD_MODAL:
      return {
        ...state,
        scratchCardModal: data,
      };
    case SET_MULTIPLE_SKU_MODAL:
      return {
        ...state,
        multipleSkuModal: data,
      };
    default:
      return state;
  }
};

export default modalReducer;

const _mandatoryKeys = {
  key: '',
  txnid: '', // OrderId
  amount: '', //float with 2 decimal
  productInfo: '',
  firstname: '',
  email: '',
  phone: '',
  lastname: '',
  udf1: '',
  udf2: '',
  udf3: '',
  surl: '',
  furl: '',
  curl: '',
  hash: '',
  pg: '',
  enforce_paymethod: 'creditcard|debitcard|netbanking',

};

const _getPostParamsForPayu = paymentObj => {
  // const _location = window.location;
  let ignoreKeys = ['action', 'merchantCodesHash', 'paymentUrl', 'mobileSdkHash', 'detailsForMobileSdkHash', 'pgCid', 'successCb', 'failCb'];
  // let baseUrl = _location.protocol + '://' + _location.host; //+ '/api';
  let payuParams = {
    // surl: baseUrl + paymentObj.surl,
    // curl: baseUrl + paymentObj.curl,
    // furl: baseUrl + paymentObj.furl
    // surl: baseUrl + '/test-payu',
    // curl: baseUrl + '/test-payu',
    // furl: baseUrl + '/test-payu',
  };
  for(const key in paymentObj) {
    if (paymentObj.hasOwnProperty(key)) {
      const value = paymentObj[key];
      if (ignoreKeys.indexOf(key) === -1) {
        payuParams[key.toLowerCase()] = value;
      }
    }
  }
  return payuParams;
};

/**
 * Function that will redirect to a new page & pass data using submit
 * @param {string} url -> payu redirect url
 * @param {any} payuData -> JSON data to be posted
 * @returns {undefined} -> NA
 */

const _goToPayu = (url = 'https://test.payu.in/_payment', payuData) => {
  const method = 'POST';

  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = window.document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", url);

  console.log('-- Creating payu form with : ', JSON.stringify(payuData));
  // debugger;
  for (const key in payuData) {
    if (payuData.hasOwnProperty(key)) {
      let hiddenField = window.document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      if (typeof payuData[key] === typeof {}) {
        hiddenField.setAttribute("value", JSON.stringify(payuData[key]));
      } else {
        hiddenField.setAttribute("value", payuData[key]);
      }
      form.appendChild(hiddenField);
    }
  }
  window.document.body.appendChild(form);
  form.submit();
};

const makePayment = paymentData => {
  const payuData = _getPostParamsForPayu(paymentData);
  _goToPayu(paymentData.payuUrl, payuData);
};

const makeBnplPayment = data => {
  if (data?.acsTemplate) {
    const otpHtmlEl = new DOMParser().parseFromString(data?.acsTemplate, 'text/html');
    const form = otpHtmlEl.getElementsByTagName('form')[0];
    if (form) {
      window.document.body.appendChild(form);
      form.submit();
    }
  }
};

export default { makePayment, makeBnplPayment };

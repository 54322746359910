import React from 'react';
import PropTypes from 'prop-types';
import './_loader.scss';
import Lottie from '../../../common/Lottie';
import animationData from './generic-loader.json';

const Loader = ({ position = 'fixed' }) => {
  // const loaderRef = React.useRef();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div id="lottie-loader" className="lottie-loader" style={{ position }}>
      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
  );
};

Loader.propTypes = { position: PropTypes.oneOf(['absolute', 'fixed']) };

export default Loader;

import React from 'react';
import animationData from '../../../assets/lottie-animations/button-inprogress/ButtonInprogress.json';
import Lottie from '../Lottie';

const ButtonLoader = props => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return <Lottie speed={4} options={defaultOptions} width={120} height={35} />;
};

export default ButtonLoader;

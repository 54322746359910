import request from 'hb-redux-store/util/request';

export const getBlogsWithCategoryApi = () =>
  request.get('/api/blogsWithCategories');

export const getBlogsWithSegmentApi = () =>
  request.get('/api/blogsWithCategories');

export const getBlogBySlugApi = ({ slug }) => request.get(`/api/blogs/${slug}`);

export const getRecentBlogsApi = ({ page = 0, count = 12 }) =>
  request.get('/api/blogs', { page, count });
